import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

const mainLayout = () =>
    import('./layouts/main-layout/main-layout.component').then((m) => m.MainLayoutComponent);

export const routes: Routes = [
    {
        path: 'callback',
        loadComponent: () =>
            import('./pages/callback/callback.component').then((c) => c.CallbackComponent),
    },
    {
        path: 'app',
        loadComponent: mainLayout,
        children: [
            {
                path: 'home',
                loadComponent: () =>
                    import('./pages/root/root.component').then((c) => c.RootComponent),
            },
            {
                path: 'auth',
                loadComponent: () =>
                    import('./pages/root/root.component').then((c) => c.RootComponent),
                canActivate: [authGuard],
            },
            {
                path: '**',
                redirectTo: '/app/home',
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/app/auth',
    },
];
