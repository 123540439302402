import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { OauthService } from '../services/oauth.service';

export const sessionRecoveryInterceptor: HttpInterceptorFn = (req, next: HttpHandlerFn) => {
    const router = inject(Router);
    const oauthService = inject(OauthService);

    return next(req).pipe(
        catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                localStorage.setItem('nextUrl', router.url);
                oauthService.startAuthFlow(router.url);
                return throwError(() => new Error('Unauthorized access - redirecting to login'));
            }

            return throwError(() => err);
        }),
    );
};
