import {
    ApplicationConfig,
    inject,
    provideAppInitializer,
    provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { helpCircleOutline, language, person } from 'ionicons/icons';
import { OauthService } from './core/services/oauth.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { LanguageService } from './core/services/language.service';
import { SecureStorageService } from './core/services/storage.service';
import { createServiceProvider } from './core/utils/api-service-provider.utils';
import { environment } from '../environments/environment';
import { IdentityService } from '@finboot/ng-identity-management';
import { IdentityServiceWrapper } from './core/services/IdentityServiceWrapper';
import { sessionRecoveryInterceptor } from './core/interceptors/sessionRecoveryInterceptor';
import { accessTokenInterceptor } from './core/interceptors/access-token.interceptor';

addIcons({ helpCircleOutline, person, language });

export const appConfig: ApplicationConfig = {
    providers: [
        provideIonicAngular({}),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideHttpClient(withInterceptors([accessTokenInterceptor, sessionRecoveryInterceptor])),
        provideAnimations(),

        // App Config
        { provide: APP_BASE_HREF, useValue: '/' },
        provideAppInitializer(() => {
            const initializerFn = ((languageService: LanguageService) => async () => {
                languageService.initialiseLanguage();
                return await languageService.loadTranslation();
            })(inject(LanguageService));
            return initializerFn();
        }),

        // Services
        createServiceProvider(IdentityService, environment.apiUrl),

        LanguageService,
        SecureStorageService,
        OauthService,
        IdentityServiceWrapper,
    ],
};
