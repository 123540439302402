import { HttpClient } from '@angular/common/http';
import { Provider } from '@angular/core';
import { Configuration } from '@finboot/ng-identity-management';
import { OauthService } from '../services/oauth.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
// We need to use any here because config can come from multiple libs but we coerce it to be form one of the libs
type ServiceType<T> = new (http: HttpClient, basePath: string | string[], configuration?: any) => T;
/* eslint-enable @typescript-eslint/no-explicit-any */

export function createServiceProvider<T>(serviceClass: ServiceType<T>, basePath: string): Provider {
    return {
        provide: serviceClass,
        useFactory: (http: HttpClient, oauthService: OauthService) => {
            const config = new Configuration({
                basePath: basePath,
                credentials: {
                    bearerAuth: () => {
                        return oauthService.getAccessToken();
                    },
                },
            });
            return new serviceClass(http, basePath, config);
        },
        deps: [HttpClient, OauthService],
    };
}
